import React from "react";
import * as missionApi from "../../api/missionApi";
import { useUser } from "../../contexts/user_provider";
import StateIcon from "../StateIcon";
import { Badge } from "react-bootstrap";
import * as api from "../../api/api";

export default function AppStatusMessage({ mission, app, allApps, missionHandlerStarted, setMissionHandlerStarted }) {
    const { getUserIsAdmin } = useUser();

    const dependencyApps = missionApi.getMissionApps(mission)
        .filter((bgApp) => bgApp?.type === "background")
        .filter((bgApp) => bgApp?.rootApp === app?.id)
        .map((bgApp) => ({
            ...bgApp,
            fullApp: allApps.find((a) => a.id === bgApp.id) || {},
        }))
        .sort((a, b) => {
            const nameA = a.fullApp.name?.toLowerCase() || "";
            const nameB = b.fullApp.name?.toLowerCase() || "";

            // Prioritize "export" first, then "call"
            if (nameA.includes("export") && !nameB.includes("export")) return -1;
            if (!nameA.includes("export") && nameB.includes("export")) return 1;
            if (nameA.includes("call") && !nameB.includes("call")) return -1;
            if (!nameA.includes("call") && nameB.includes("call")) return 1;

            return 0; // Otherwise, keep the original order
        });


    return (
        <div className="mt-1">
            {/* First row: Icon and App Name */}
            <div className="d-flex align-items-center">
                <StateIcon
                    state={missionApi.getApplicationState(mission, app)}
                    className="me-3"
                />
                <span className="ms-2" style={{ marginTop: "-2px" }} >{app?.name || "Unknown app"}</span>
            </div>

            {/* Second row: Badges (aligned with app name) */}
            {getUserIsAdmin() && dependencyApps.length > 0 && (
                <>
                    <div className="ms-4">
                        <small className="text-muted ms-3">Depends on:</small>
                    </div>
                    <div className="d-flex flex-wrap ms-4 ps-2">
                        {dependencyApps
                            .map((dependencyApp) => {
                                return (
                                    <Badge
                                        key={dependencyApp.id}
                                        className={`ms-2 mb-1 rounded-pill border border-secondary text-secondary bg-transparent fw-light`}
                                        style={{ padding: "4px 8px", fontSize: "0.85rem", cursor: "default" }}
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            try {
                                                api.setAppPending(mission.organizationId, mission.locationId, mission.id, dependencyApp.id)
                                                if (!missionHandlerStarted) {
                                                    const restart = true;
                                                    const noFly = true;
                                                    api.restartMissionHandler(mission.organizationId, mission.locationId, mission.id, restart, noFly);
                                                    setMissionHandlerStarted(true);
                                                }
                                            } catch (error) {
                                                console.error("Error restarting app:", error);
                                            }
                                        }}
                                    >
                                        <StateIcon
                                            state={dependencyApp.state}
                                            className="me-1"
                                        />
                                        {dependencyApp.fullApp?.name || "Unknown app"}
                                    </Badge>
                                );
                            })}
                    </div>
                </>
            )}
        </div>
    );
}
