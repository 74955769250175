import { useEffect, useState } from 'react';
import * as api from "../api/api";

function useMapPrecomputeProgress({ organizationId, locationId, location: providedLocation }) {
    const [location, setLocation] = useState(providedLocation);
    const [precomputeProgress, setPrecomputeProgress] = useState(1.0);

    useEffect(() => {
        if (!providedLocation && organizationId && locationId) {
            const unsubscribe = api.subscribeLocation(organizationId, locationId, setLocation);
            return () => unsubscribe?.();
        }
    }, [organizationId, locationId, providedLocation]);
    

    useEffect(() => {
        if (location?.routePlanner) {
            console.log("precomputeProgress:", location.routePlanner.precomputeProgress)
            setPrecomputeProgress(location.routePlanner.precomputeProgress ?? 1.0);
        } else {
            setPrecomputeProgress(1.0);
        }
    }, [location])

    return precomputeProgress;
}

export default useMapPrecomputeProgress;