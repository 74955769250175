import moment from 'moment-timezone';
import React from "react";
import { Button, Card, Col, Form, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { FaDownload, FaUpload, FaMap, FaExclamationTriangle } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import CancelButton from "../../components/CancelButton";
import CorvusSettingLabel from "../../components/CorvusSettingLabel";
import CorvusTextSetting from "../../components/CorvusTextSetting";
import Layout, {
    LocationSettingsBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import { ProgressingSaveButton } from "../../components/ProgressingSaveButton";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
import { useForm } from "react-hook-form"
import BooleanFormGroup from '../../components/FormGroups/BooleanFormGroup.jsx';
import TextFormGroup from "../../components/FormGroups/TextFormGroup.jsx";
import { FaSave } from "react-icons/fa";
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import MapOptimizationAlert from '../../components/MapOptimizationAlert/index.jsx';

var fileDownload = require("js-file-download");

function MarkerTypeSelectButton(props) {
    return (
        <Form.Select
            value={props.value ? props.value : 'ARGUS'}
            onChange={(event) => props.onChange(event.target.value)}
        >
            <option value='ARGUS'>Argus</option>
            <option value='ARUCO'>Aruco</option>
        </Form.Select>
    );
}

function TimezoneSelectButton(props) {
    let timezones = moment.tz.names();

    // Filter out 'GMT-' timezones
    timezones = timezones.filter(tz => !tz.includes('GMT'));

    const formatTimezoneName = (name) => {
        let parts = name.split('/');
        if (parts.length > 1) {
            return `${parts[1]} (${parts[0]})`;
        }
        return name;
    };

    const utcName = 'UTC';
    return (
        <Form.Select
            value={props.value ? props.value : utcName}
            onChange={(event) => props.onChange(event.target.value)}
        >
            {timezones.map(tz =>
                <option key={tz} value={tz}>
                    {formatTimezoneName(tz)}
                </option>
            )}
        </Form.Select>
    );
}

function ConfigDownloadCSVButton(props) {
    async function onDownload() {
        const csvData = await props.onDownload();
        fileDownload(csvData + "\n", `${props.name}.csv`);
    }

    // async function onDownloadJson() {
    //     const jsonData = await props.onDownload();
    //     const stringData = api.flyzonesJsonToCsv(jsonData);
    //     // const stringData = JSON.stringify(jsonData, null, 4);
    //     fileDownload(stringData, `${props.name}.json`);
    // }

    return (
        <div className="d-grid gap-2 mb-2">
            {/* TODO : Make button a block */}
            <Button
                disabled={props.disabled}
                variant="outline-secondary"
                onClick={onDownload}
            >
                <FaDownload className="text-success me-2" />
                CSV Download
            </Button>
        </div>
    );
}

function ConfigUploadCSVButton(props) {
    const inputFile = React.useRef(null);
    let fileReader;
    const [error, setError] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);

    const handleFileRead = async (e) => {
        const fileText = fileReader.result;
        try {
            await props.onUpload(fileText);
        } catch (err) {
            setError(err.message);
            setShowModal(true);
        }

        // Clear the file input value after handling the file read (needed for Chrome)
        inputFile.current.value = null;
    };

    const handleFileChosen = (file) => {
        if (!file.name.endsWith('.csv')) {
            setError('Invalid file format. Please upload a *.csv file.');
            setShowModal(true);
            return;
        }

        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    };

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <div className="d-grid gap-2">
            <Form.Control
                type="file"
                style={{ display: "none" }}
                ref={inputFile}
                onChange={(e) => handleFileChosen(e.target.files[0])}
            />
            <Button
                disabled={props.disabled}
                variant="outline-secondary"
                onClick={onButtonClick}
            >
                <FaUpload className="text-danger me-2" />
                CSV Upload
            </Button>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><FaExclamationTriangle className='me-2' />Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{error}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

function ConfigUploadJSONButton(props) {
    const inputFile = React.useRef(null);
    let fileReader;
    const [error, setError] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);

    const handleFileRead = async (e) => {
        try {
            const text = fileReader.result;
            const data = JSON.parse(text);
            await props.onUpload(data);
        } catch (err) {
            setError(err.message);
            setShowModal(true);
        }

        // Clear the file input value after handling the file read (needed for Chrome)
        inputFile.current.value = null;
    };

    const handleFileChosen = (file) => {
        if (!file.name.endsWith('.json')) {
            setError('Invalid file format. Please upload a *.json file.');
            setShowModal(true);
            return;
        }

        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    };

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <div className="d-grid gap-2">
            <Form.Control
                type="file"
                style={{ display: "none" }}
                ref={inputFile}
                onChange={(e) => handleFileChosen(e.target.files[0])}
            />
            <Button
                disabled={props.disabled}
                variant="outline-secondary"
                onClick={onButtonClick}
            >
                <FaUpload className="text-danger me-2" />
                JSON Upload
            </Button>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><FaExclamationTriangle className='me-2' />Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{error}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

function ConfigDownloadJSONButton(props) {
    // async function onDownload() {
    //     const data = await props.onDownload();
    //     fileDownload(data + "\n", `${props.name}.json`);
    // }

    async function onDownload() {
        const jsonData = await props.onDownload();
        const stringData = JSON.stringify(jsonData, null, 4);
        fileDownload(stringData, `${props.name}.json`);
    }

    return (
        <div className="d-grid gap-2 mb-2">
            {/* TODO : Make button a block */}
            <Button
                disabled={props.disabled}
                variant="outline-secondary"
                onClick={onDownload}
            >
                <FaDownload className="text-success me-2" />
                JSON Download
            </Button>
        </div>
    );
}

function LocationSettings(props) {
    const [isLoading, setIsLoading] = React.useState(false);

    const versionSuggestions = ['production', 'development'];
    const numberFromString = z.coerce.number({ invalid_type_error: "Enter a valid number" });
    const locationSettingsConfigSchema = z.object({
        missionHandler: z.object({
            version: z.string().min(1, { message: "Mission handler version is required" }),
            maxAutonomousFlights: numberFromString.nonnegative(),
        }),
        visualization: z.object({
            showDiskSpaceOnDronePage: z.boolean(),
        }),
        mobileDock: z.object({
            takeoffHeight: numberFromString.positive()
        })
    });
    const { control, handleSubmit, reset, setValue, formState: { errors, isDirty, isSubmitting } } = useForm({
        resolver: zodResolver(locationSettingsConfigSchema),
    });

    const onSubmit = async (locationSettingsUpdate) => {
        console.log(locationSettingsUpdate);
        await api.updateLocationSettings(props.organizationId, props.locationId, locationSettingsUpdate)
        reset(locationSettingsUpdate); // Reset the form with the latest saved state
    }

    React.useEffect(() => {
        if (props.location) {
            setIsLoading(false);
            reset(props.location);
        } else {
            setIsLoading(true);
        }
    }, [props.location, reset, setValue]);

    return (
        <Card className="mt-4">
            <Card.Body className="px-4">
                {isLoading ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <h4>Settings</h4>
                        <h6>Mission handler</h6>
                        <TextFormGroup label="Version"
                            name="missionHandler.version"
                            control={control}
                            errors={errors}
                            defaultValue="production"
                            suggestedOptions={versionSuggestions}
                            setValue={setValue} />
                        <TextFormGroup label="Max autonomous flights"
                            name="missionHandler.maxAutonomousFlights"
                            control={control}
                            errors={errors}
                            defaultValue="999"
                            setValue={setValue} />

                        <br />
                        <h6>Visualization</h6>
                        <BooleanFormGroup
                            label="Show disk space on drone page"
                            name="visualization.showDiskSpaceOnDronePage"
                            control={control}
                            errors={errors}
                            defaultValue={false}
                            setValue={setValue}
                        />

                        <br />
                        <h6>Mobile dock (only rpv3)</h6>
                        <TextFormGroup
                            label="Takeoff height"
                            name="mobileDock.takeoffHeight"
                            control={control}
                            errors={errors}
                            defaultValue={1}
                            setValue={setValue}
                        />
                        <Button variant="success" disabled={isSubmitting || !isDirty} type="submit" className="mb-2 mt-1">
                            <FaSave className="mb-1 me-1" />
                            {isSubmitting ? "Saving..." : "Save"}
                        </Button>
                    </Form>
                )
                }
            </Card.Body>
        </Card >
    );
}

export default function LocationSettingsPage() {
    const [location, setLocation] = React.useState({
        name: "",
        markerType: "ARGUS",
        timezone: "UTC",
    });
    const [saved, setSaved] = React.useState(true);
    const { organizationId, locationId } = useParams();
    const [areas, setAreas] = React.useState({});
    const [markers, setMarkers] = React.useState({ markers: [] });
    const [flyzones, setFlyzones] = React.useState({ flyzones: [] });
    const { getUserIsAdmin } = useUser();
    const history = useHistory();
    const isNewLocation = locationId === undefined;

    // Get location
    React.useEffect(() => {
        if (!isNewLocation) {
            api.getLocation(organizationId, locationId, setLocation);
        }
    }, [organizationId, locationId, isNewLocation]);

    // Subscribe to markers
    React.useEffect(() => {
        if (getUserIsAdmin()) {
            return api.subscribeMarkers(organizationId, locationId, setMarkers);
        }
    }, [getUserIsAdmin, organizationId, locationId]);

    // Subscribe to areas
    React.useEffect(() => {
        if (getUserIsAdmin()) {
            return api.subscribeAreas(organizationId, locationId, setAreas, true);
        }
    }, [getUserIsAdmin, organizationId, locationId]);

    // Subscribe to flyzones
    React.useEffect(() => {
        if (getUserIsAdmin()) {
            return api.subscribeFlyzones(organizationId, locationId, setFlyzones, true);
        }
    }, [getUserIsAdmin, organizationId, locationId]);


    function onSaveLocation() {
        if (isNewLocation) {
            return api.createLocation(organizationId, location)
                .then((locationId) => {
                    console.log(`Location ${locationId}`);
                    api.saveLocation(organizationId, locationId, { ...location, id: locationId })
                        .then(() => {
                            history.push(urls.location(organizationId, locationId));
                            setSaved(true);
                        });
                })

        } else {
            return api.saveLocation(organizationId, locationId, location)
                .then(() => {
                    setSaved(true);
                });
        }
    }

    function changeLocation(newKeyValues) {
        setLocation({ ...location, ...newKeyValues, });
        setSaved(false);
    }

    function setLocationName(event) {
        changeLocation({ name: event.target.value });
    }

    // function setLocationDescription(event) {
    //     setLocation({ ...location, description: event.target.value });
    // }

    function setLocationMarkerType(markerType) {
        changeLocation({ markerType });
    }

    function setLocationTimezone(timezone) {
        changeLocation({ timezone });
    }

    function getAreasCount() {
        if (!areas.hasOwnProperty("areas")) {
            return 0;
        }
        const rowKeys = Object.keys(areas.areas);
        if (rowKeys <= 0) return 0;
        const columnKeys = Object.keys(areas.areas[rowKeys[0]]);
        return rowKeys.length * columnKeys.length;
    }

    function getupdateTime(data) {
        if (data.hasOwnProperty("updatedAt") && data.updatedAt) {
            const timestamp = data.updatedAt.toDate();
            const year = timestamp.getFullYear();
            const month = String(timestamp.getMonth() + 1).padStart(2, '0');
            const day = String(timestamp.getDate()).padStart(2, '0');
            const hours = String(timestamp.getHours()).padStart(2, '0');
            const minutes = String(timestamp.getMinutes()).padStart(2, '0');
            const seconds = String(timestamp.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
        return 'N/A';
    }

    return (
        <Layout>
            <LocationSettingsBreadcrumbContainer />
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.location(organizationId, locationId)} />
                        Location
                    </>
                }>
                    <div>
                        <Button
                            variant="outline-secondary"
                            as={Link}
                            to={urls.locationMap(organizationId, locationId)}
                        >
                            <FaMap className="me-2" />
                            View Map
                        </Button>
                    </div>
                </Title>
                <Card.Body className="p-4 py-0">
                    <CorvusTextSetting
                        name="Name"
                        className="mb-2"
                        defaultValue={location?.name}
                        placeholder="Location Name ..."
                        onChange={setLocationName}
                    />
                    {/* <CorvusTextSetting
                        name="Description"
                        className="mb-2"
                        defaultValue={location?.description}
                        placeholder="Location Description ..."
                        onChange={setLocationDescription}
                    /> */}
                    <InputGroup className='mb-2'>
                        <CorvusSettingLabel name='Marker Type' />
                        <MarkerTypeSelectButton
                            onChange={setLocationMarkerType}
                            value={location?.markerType}
                        />
                    </InputGroup>
                    <InputGroup>
                        <CorvusSettingLabel name='Timezone' />
                        <TimezoneSelectButton
                            onChange={setLocationTimezone}
                            value={location?.timezone}
                        />
                    </InputGroup>
                </Card.Body>
                <Card.Body className="p-4">
                    <ProgressingSaveButton
                        onSave={onSaveLocation}
                        setSaved={setSaved}
                        saved={saved}
                        className="me-2"
                    />
                    <CancelButton
                        href={urls.location(organizationId, locationId)}
                        className="me-2"
                    />
                </Card.Body>
            </Card>
            <MapOptimizationAlert
                organizationId={organizationId}
                locationId={locationId}
                className="mt-4 mb-0"
            />
            <Row>
                {getUserIsAdmin() && (
                    <>
                        <Col xs={12} md={4} className="mt-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Markers{` (${(markers.markers || []).length})`}
                                    </Card.Title>
                                    <Card.Subtitle>
                                        Updated:{` ${getupdateTime(markers)}`}
                                    </Card.Subtitle>
                                    <ConfigDownloadCSVButton
                                        name={`markers_${locationId}`}
                                        onDownload={async () =>
                                            await api.getMarkersAsCsv(
                                                organizationId,
                                                locationId
                                            )
                                        }
                                    />
                                    <ConfigUploadCSVButton
                                        onUpload={async (markersCsv) =>
                                            await api.setMarkersFromCsv(
                                                organizationId,
                                                locationId,
                                                markersCsv
                                            )
                                        }
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} className="mt-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Flyzones{` (${(flyzones.flyzones || []).length})`}
                                    </Card.Title>
                                    <Card.Subtitle>
                                        Updated:{` ${getupdateTime(flyzones)}`}
                                    </Card.Subtitle>
                                    <ConfigDownloadCSVButton
                                        name={`flyzones_${locationId}`}
                                        onDownload={async () =>
                                            await api.getFlyzonesAsCsv(
                                                organizationId,
                                                locationId
                                            )
                                        }
                                    />
                                    <ConfigUploadCSVButton
                                        onUpload={async (flyzonesCsv) => {
                                            await api.setFlyzonesFromCsv(
                                                organizationId,
                                                locationId,
                                                flyzonesCsv
                                            )
                                        }}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} className="mt-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Areas{` (${getAreasCount()})`}
                                    </Card.Title>
                                    <Card.Subtitle>
                                        Updated:{` ${getupdateTime(areas)}`}
                                    </Card.Subtitle>
                                    <ConfigDownloadJSONButton
                                        name={`areas_${organizationId}_${locationId}`}
                                        onDownload={async () =>
                                            await api.getAreasAsJson(
                                                organizationId,
                                                locationId
                                            )
                                        }
                                    />
                                    <ConfigUploadJSONButton
                                        onUpload={async (areasJsonString) =>
                                            await api.setAreasFromJson(
                                                organizationId,
                                                locationId,
                                                areasJsonString
                                            )
                                        }
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </>
                )}
            </Row>
            {
                getUserIsAdmin() &&
                (<LocationSettings
                    location={location}
                    organizationId={organizationId}
                    locationId={locationId}>
                </LocationSettings>)
            }
        </Layout>
    );
}
