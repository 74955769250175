import React from "react";
import { ListGroup, Card, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaFileAlt, FaChevronRight } from "react-icons/fa";
import * as api from "../../api/api";
import { STATE_SUCCEEDED } from "../../api/missionApi";
import TimeDisplay from "../TimeDisplay";

export function ResultItem({ result }) {
    return <ListGroup.Item className="px-3 py-2 d-flex justify-content-between align-items-start"
        as={Link}
        action
        onClick={(event) => {
            event.preventDefault();
            api.downloadResult(result?.organizationId, result?.locationId, result?.missionId, result.id, result.fileName);
        }
        }>
        <div className="d-flex align-items-center w-100">
            <div className="d-flex align-items-center flex-grow-1">
                <FaFileAlt className="me-2" color="#6c757d" size={24} />
                <div>
                    {result?.app?.name}
                    {result.timestamp &&
                        <div className="text-muted" style={{ fontSize: "14px" }}>
                            <TimeDisplay
                                timestamp={result.timestamp.seconds}
                                format="YYYY-MM-DD HH:mm:ss"
                                organizationId={result.organizationId}
                                locationId={result.locationId}
                            />
                        </div>
                    }
                </div>
            </div>
            <FaChevronRight className="ms-auto" style={{ color: "#b9b9b9" }} />
        </div>
    </ListGroup.Item>
}

export default function ResultsList(props) {
    const [results, setResults] = React.useState({});
    const [apps, setApps] = React.useState([]);
    const resultsUnsubRef = React.useRef({});

    React.useEffect(() => {
        async function getCorvusApps() {
            await api.getCorvusApps(setApps);
        }
        getCorvusApps();
    }, []);

    function findApp(apps, app) {
        return apps.find((a) => a.id === app.id);
    }

    React.useEffect(() => {
        const sortedMissions = [...props.missions].sort((a, b) => b.timestamp - a.timestamp);
        const newUnsubs = { ...resultsUnsubRef.current };
        console.log("missions", sortedMissions);
        sortedMissions.forEach((mission) => {
            if (!newUnsubs[mission.id]) {
                newUnsubs[mission.id] = api.subscribeResults(
                    mission.organizationId,
                    mission.locationId,
                    mission.id,
                    (data) => {
                        setResults((prevResults) => {
                            const updatedResults = { ...prevResults };

                            data.forEach((result) => {
                                // enrich the results with extra information that we need later
                                result.missionId = mission.id;
                                result.locationId = mission.locationId;
                                result.organizationId = mission.organizationId;
                                result.timestamp = mission.timestamp;
                                result.app = findApp(apps, result.app);
                                const appId = result.app.id;
                                console.log("apps", result.missionId, result.app);
                                // Find the latest mission for this app
                                const latestMission = sortedMissions
                                    .filter((m) => m.apps.filter(app => app?.state === STATE_SUCCEEDED).map((app) => app.id).includes(appId))
                                    .reduce((latest, current) =>
                                        current.timestamp > latest.timestamp ? current : latest,
                                        { timestamp: 0 }
                                    );
                                // Update only if the mission is the latest one
                                if (latestMission.id === mission.id) {
                                    console.log("apps add result", result.missionId, result);
                                    updatedResults[appId] = result;
                                }
                            });
                            console.log("results", updatedResults);
                            return updatedResults;
                        });
                    }
                );
            }
        });

        // Unsubscribe from removed missions
        Object.keys(newUnsubs).forEach((missionId) => {
            if (!props.missions.some((m) => m.id === missionId)) {
                newUnsubs[missionId](); // Call the unsubscribe function
                delete newUnsubs[missionId];
            }
        });

        resultsUnsubRef.current = newUnsubs;

        return () => {
            // Cleanup all subscriptions when component unmounts
            Object.values(resultsUnsubRef.current).forEach((unsub) => unsub());
            resultsUnsubRef.current = {};
        };
    }, [props.missions, apps]);

    const latestResults = Object.values(results)
        .filter(result => result.fileName)
        .sort((a, b) => a?.app?.name?.localeCompare(b?.app?.name ?? '') ?? 0);

    return (
        <Card className={props.className}>
            <Card.Header className="d-flex justify-content-between">
                <div style={{ fontWeight: 'bold' }}>
                    {props.title || 'Latest reports'}
                </div>
            </Card.Header>
            <Card.Body className="px-0 py-0">
                <ListGroup variant="flush">
                    {// Show download button for every result of which the fileName is set
                        latestResults.length > 0 ?
                            (latestResults.map((result) =>
                            (
                                <ResultItem result={result} />
                            ))
                            ) :
                            (// Show download button for every result of which the fileName is set
                                <ListGroup.Item className="px-3 py-2 d-flex justify-content-between align-items-start">
                                    <div className="d-flex align-items-center w-100">
                                        <div className="d-flex align-items-center flex-grow-1">
                                            <Alert className="text-center mb-0 w-100" variant="primary">
                                                <div className="text-muted">
                                                    No recent reports
                                                </div>
                                            </Alert>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            )
                    }
                </ListGroup >
            </Card.Body>
        </Card >
    )
}
