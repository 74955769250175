import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Container, Stage } from "@inlet/react-pixi";
import Viewport from "./Viewport";
import * as api from "../../api/api";
import { FaClock, FaExclamationCircle, FaRedo } from "react-icons/fa";
import { MapProvider } from "./MapContext";
// import { XAxis } from "./mapElements/XAxis";
// import { YAxis } from "./mapElements/YAxis";
// import { ScrollBorder } from "./mapElements/ScrollBorder";
import { WorldBorder } from "./mapElements/WorldBorder";
import { LocationTitle } from "./mapElements/LocationTitle";
import { Area } from "./mapElements/Area";
import { Marker } from "./mapElements/Marker";
import { Measurement } from "./mapElements/Measurement";
import { Drone } from "./mapElements/Drone";
import { Dock } from "./mapElements/Dock";
import { Flyzone } from "./mapElements/Flyzone";
import { DroneCommands } from "./mapElements/DroneCommands";
import { ReturnPath } from "./mapElements/ReturnPath";
import { Point } from "./mapElements/Point.jsx";
import { replaceCommandsSettingsWithArguments } from "./utils/FlightPathUtils";
import { Alert, Button, Spinner } from "react-bootstrap";
import { useUser } from "../../contexts/user_provider";
import { useDronesOnline } from "../../contexts/DroneOnlineProvider.jsx";
import urls from "../../urls.js"

const worldMapScaleFactor = 10;
const worldMargin = 20; // Margin in meters between world and viewport boundary

// Conversion functions
function worldToMap({ mapHeight, ...rest }) {
    const mapCoordinates = {};

    // Iterate over all the provided properties
    for (const key in rest) {
        if (Object.hasOwnProperty.call(rest, key)) {
            const value = rest[key];

            if (key === 'y' && mapHeight !== undefined) {
                // Our y has origin at bottom left and the map has origin at top left
                // We need to compensate for that
                mapCoordinates.y = mapHeight - value * worldMapScaleFactor;
            } else {
                // Apply scaling for all other properties
                mapCoordinates[key] = value * worldMapScaleFactor;
            }
        }
    }

    // If y and height are both provided, we need to move y from 
    // the bottom left corner to the top left corner
    if ('y' in mapCoordinates && 'height' in mapCoordinates) {
        mapCoordinates.y = mapCoordinates.y - mapCoordinates.height;
    }

    return mapCoordinates;
}

export default function Map({
    organizationId,
    locationId,
    flightId,
    missionId,
    taskPreview, // taskId + droneId
    resumePreview, // missionId + droneId
    point, // x + y
    setTaskPreviewSuccess,
}) {
    const mapRef = useRef(null);
    const viewportRef = useRef(null);
    const [frameDimensions, setFrameDimensions] = useState(null);
    const [worldDimensions, setWorldDimensions] = useState(null);
    const { getUserIsAdmin } = useUser();
    const isAdmin = useMemo(() => getUserIsAdmin(), [getUserIsAdmin]);
    const [disableAutoFocus, setDisableAutoFocus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hoverTextInfo, setHoverTextInfo] = useState(null);
    const [hoverImageInfo, setHoverImageInfo] = useState(null);
    const [hoverImageError, setHoverImageError] = useState(false);
    const [userIsDragging, setUserIsDragging] = useState(false);

    // These components are always loaded
    const [location, setLocation] = useState();
    const [areas, setAreas] = useState({});
    const [flyzones, setFlyzones] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [docks, setDocks] = useState([]);
    const [droneConfigs, setDroneConfigs] = useState([]);
    const [droneFeedback, setDroneFeedback] = useState({});
    const onlineStatuses = useDronesOnline(Object.keys(droneFeedback));

    // These components are loaded based on inputs
    const [flight, setFlight] = useState();
    const [measurements, setMeasurements] = useState([]);
    const [taskPreviewCommands, setTaskPreviewCommands] = useState([]);
    const [resumePreviewCommands, setResumePreviewCommands] = useState([]);
    const [previewAlert, setPreviewAlert] = useState(null);
    const [hasSentWakeUp, setHasSentWakeUp] = useState(false);

    // Memoized data
    const flightCommands = useMemo(() => flight?.commands || [], [flight?.commands]);
    const fligthAbortEvents = useMemo(() => {
        return flight?.events.filter(event => event.type === "FLIGHT_ABORTED") || [];
    }, [flight?.events]);

    const drones = useMemo(() => {
        return Object.keys(droneFeedback).map(droneId => {
            const droneData = droneFeedback[droneId];
            if (!droneData) return null;
            if (!droneData.widgets.position) return null;
            if (!droneData.widgets.orientation) return null;

            return {
                droneId,
                x: droneData.widgets.position.data.x,
                y: droneData.widgets.position.data.y,
                yaw: droneData.widgets.orientation.data.yaw,
                isSleeping: droneData?.widgets?.status?.data === "Sleeping",
                isOnline: onlineStatuses[droneId],
                bestReturnCommand: droneData.widgets?.bestReturnCommand?.data
            };
        }).filter(Boolean); // To filter out the nulls
    }, [droneFeedback, onlineStatuses]);

    const routePlannerVersions = useMemo(() => {
        const versionsMap = {};
        droneConfigs.forEach((config) => {
            versionsMap[config.id] = config.routeplanner?.version;
        });
        return versionsMap;
    }, [droneConfigs]);

    const mapContextValue = useMemo(() => ({
        worldToMap: (coordinates) => worldToMap({
            ...coordinates,
            mapHeight: (worldDimensions?.height ?? 0) * worldMapScaleFactor
        }),
        userIsDragging,
        setUserIsDragging
    }), [worldDimensions?.height, userIsDragging]);

    // Handle resizing
    useEffect(() => {
        const updateDimensions = () => {
            if (mapRef.current) {
                const { width, height } = mapRef.current.getBoundingClientRect();
                setFrameDimensions({ width, height: height });
            }
        };

        // Set initial dimensions
        updateDimensions();

        // Add event listener to update dimensions on window resize
        window.addEventListener("resize", updateDimensions);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, [isAdmin, previewAlert]);

    // Set world dimensions
    useEffect(() => {
        // Generalized function to get max x and y values
        function getMaxValues(data, accessor) {
            return data.reduce(
                (acc, item) => {
                    const { x, y } = accessor(item);

                    return {
                        xMax: !isNaN(x) ? Math.max(acc.xMax, x) : acc.xMax,
                        yMax: !isNaN(y) ? Math.max(acc.yMax, y) : acc.yMax
                    };
                },
                { xMax: 0, yMax: 0 }
            );
        }

        // This defines the data as a list and how to get the max x and y from every item of that list
        const objectAccessors = [
            { data: Object.values(areas).flatMap(kap => Object.values(kap)), accessor: area => ({ x: parseFloat(area.max.x), y: parseFloat(area.max.y) }) },
            { data: markers?.markers || [], accessor: marker => ({ x: parseFloat(marker.x), y: parseFloat(marker.y) }) },
            { data: flyzones || [], accessor: flyzone => ({ x: parseFloat(flyzone.x_max), y: parseFloat(flyzone.y_max) }) }
            // Add more types here in the future as needed
        ];

        // Calculate max x and y values across all object types
        const totalMaxValues = objectAccessors.reduce(
            (acc, { data, accessor }) => {
                const { xMax, yMax } = getMaxValues(data, accessor);
                return {
                    xMax: Math.max(acc.xMax, xMax),
                    yMax: Math.max(acc.yMax, yMax)
                };
            },
            { xMax: 0, yMax: 0 }
        );

        // Set the world dimensions using the final max values
        setWorldDimensions({ width: totalMaxValues.xMax, height: totalMaxValues.yMax });
    }, [areas, flyzones, markers]);

    useEffect(() => {
        return api.subscribeLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    useEffect(() => {
        return api.subscribeMarkers(organizationId, locationId, setMarkers);
    }, [organizationId, locationId]);

    useEffect(() => {
        return api.subscribeAreas(organizationId, locationId, areas => {
            setAreas(areas);
            return areas;
        });
    }, [organizationId, locationId]);

    useEffect(() => {
        return api.subscribeFlyzones(organizationId, locationId, setFlyzones);
    }, [organizationId, locationId]);

    useEffect(() => {
        if (organizationId && locationId) {
            return api.subscribeDocks(organizationId, locationId, setDocks);
        }
    }, [organizationId, locationId]);

    useEffect(() => {
        return api.subscribeDroneConfigs(organizationId, locationId, setDroneConfigs);
    }, [organizationId, locationId]);

    useEffect(() => {
        return api.subscribeDronesFeedback(droneConfigs, setDroneFeedback);
    }, [droneConfigs]);


    // Subscribe to flightId if it is specified
    useEffect(() => {
        if (flightId) {
            return api.subscribeFlight(organizationId, locationId, flightId, (newFlight) => {
                if (newFlight && newFlight.commands) {
                    // Map over the commands and replace settings with arguments
                    const updatedCommands = replaceCommandsSettingsWithArguments(newFlight.commands);

                    // Update the flight object with the updated commands
                    setFlight({ ...newFlight, commands: updatedCommands });
                } else {
                    setFlight(newFlight);
                }
            });
        }
    }, [organizationId, locationId, flightId]);

    // Subscribe to measurements for mission
    useEffect(() => {
        if (missionId) {
            return api.subscribeMeasurements(
                organizationId,
                locationId,
                missionId,
                setMeasurements);
        }
    }, [organizationId, locationId, missionId]);


    const taskPreviewRoutePlannerVersion = taskPreview?.droneId && routePlannerVersions?.hasOwnProperty(taskPreview.droneId)
        ? routePlannerVersions[taskPreview.droneId]
        : undefined;

    const resumePreviewRoutePlannerVersion = resumePreview?.droneId && routePlannerVersions?.hasOwnProperty(resumePreview.droneId)
        ? routePlannerVersions[resumePreview.droneId]
        : undefined;

    const loadTaskPreview = useCallback(() => {
        if (organizationId && locationId && taskPreview && taskPreview.droneId && taskPreview.taskId && taskPreviewRoutePlannerVersion) {
            if (taskPreview.droneId === 'none' || taskPreview.taskId === 'none') {
                setTaskPreviewCommands([])
                setPreviewAlert(null);
                setTaskPreviewSuccess?.(false);
                return;
            }

            setIsLoading(true);

            api.getFlightPreview(organizationId,
                locationId,
                taskPreview.droneId,
                taskPreview.taskId,
                taskPreviewRoutePlannerVersion)
                .then(response => {
                    if (taskPreviewRoutePlannerVersion === "V2") {
                        response.data = response.data.data;
                    }

                    // It could be that response.data.data is a string "mission completed". We ignore that for now. Could be handled later
                    if (Array.isArray(response.data)) {
                        const transformedCommands = replaceCommandsSettingsWithArguments(response.data);
                        setTaskPreviewCommands(transformedCommands);
                        setTaskPreviewSuccess?.(true);
                    }

                    setPreviewAlert(null);
                })
                .catch((error) => {
                    setTaskPreviewCommands([])
                    let userErrorMessage = "";
                    if (error?.response?.status) {
                        switch (error.response.status) {
                            case 520:
                                userErrorMessage = "There is no app in the requested task. Add an app to the task.";
                                break;
                            case 521:
                                userErrorMessage = "Drone is not in a location where it is allowed to fly. Place the drone in a flyzone.";
                                break;
                            case 522:
                                userErrorMessage = "Drone has not enough space to take off. Place the drone somewhere else.";
                                break;
                            case 523:
                                userErrorMessage = "Can't find a route.";
                                break;
                            case 524:
                                userErrorMessage = "There are no commands in the requested task. Add commands to the task.";
                                break;
                            default:
                                userErrorMessage = "Can't create a flight plan."
                        }
                    }

                    let adminErrorMessage = ""
                    if (error?.response?.data?.message) {
                        adminErrorMessage = error.response.data.message;
                        console.error(adminErrorMessage);
                    } else {
                        adminErrorMessage = "Unexpected error";
                        console.error(error);
                    }
                    const errorMessage = isAdmin ? `${userErrorMessage} (${adminErrorMessage})` : userErrorMessage;
                    setPreviewAlert({ variant: 'danger', message: errorMessage, droneId: taskPreview.droneId })

                    setTaskPreviewSuccess?.(false);
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }, [organizationId, locationId, taskPreview, taskPreviewRoutePlannerVersion, isAdmin, setTaskPreviewSuccess]);

    useEffect(() => {
        loadTaskPreview();
    }, [loadTaskPreview, flyzones, areas, docks]);

    const loadResumePreview = useCallback(() => {
        if (resumePreview && resumePreview.missionId && resumePreview.droneId && resumePreviewRoutePlannerVersion) {
            setIsLoading(true);
            api.getMissionResumePreview(organizationId, locationId, resumePreview.missionId, resumePreview.droneId, resumePreviewRoutePlannerVersion)
                .then(response => {
                    if (resumePreviewRoutePlannerVersion === "V2") {
                        response.data = response.data.data;
                    }
                    // It could be that response.data.data is a string "mission completed". We ignore that for now. Could be handled later
                    if (Array.isArray(response.data)) {
                        const transformedCommands = replaceCommandsSettingsWithArguments(response.data);
                        setResumePreviewCommands(transformedCommands);
                    }
                    setPreviewAlert(null);
                }).catch((error) => {
                    setResumePreviewCommands([])
                    let userErrorMessage = "";
                    if (error?.response?.status) {
                        switch (error.response.status) {
                            case 520:
                                userErrorMessage = "There is no app in the requested task. Add an app to the task.";
                                break;
                            case 521:
                                userErrorMessage = "Drone is not in a location where it is allowed to fly. Place the drone in a flyzone.";
                                break;
                            case 522:
                                userErrorMessage = "Drone has not enough space to take off. Place the drone somewhere else.";
                                break;
                            case 523:
                                userErrorMessage = "Can't find a route.";
                                break;
                            case 524:
                                userErrorMessage = "There are no commands in the requested task. Add commands to the task.";
                                break;
                            default:
                                userErrorMessage = "Can't create a flight plan."
                        }
                    }
                    let adminErrorMessage = ""
                    if (error?.response?.data?.message) {
                        adminErrorMessage = error.response.data.message;
                        console.error(adminErrorMessage);
                    } else {
                        adminErrorMessage = "Unexpected error";
                        console.error(error);
                    }
                    const errorMessage = isAdmin ? `${userErrorMessage} (${adminErrorMessage})` : userErrorMessage;
                    setPreviewAlert({ variant: 'danger', message: errorMessage, droneId: resumePreview.droneId })
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }, [organizationId, locationId, resumePreview, resumePreviewRoutePlannerVersion, isAdmin]);

    useEffect(() => {
        loadResumePreview();
    }, [loadResumePreview, flyzones, areas, docks]);

    function getMeasurementsArea(measurements) {
        if (!measurements || measurements.length === 0) {
            return null; // Return null if the list is empty
        }

        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;

        for (const measurement of measurements) {
            const { x, y } = measurement.meta.position;

            if (x < minX) minX = x;
            if (x > maxX) maxX = x;
            if (y < minY) minY = y;
            if (y > maxY) maxY = y;
        }

        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
        };
    }
    function getCommandsArea(commands) {
        if (!commands || commands.length === 0) {
            return null; // Return null if the list is empty
        }

        let minX = Infinity;
        let maxX = -Infinity;
        let minY = Infinity;
        let maxY = -Infinity;

        for (const command of commands) {
            if (command.type === 'SCHEDULE_MOVE_XYZ' || command.type === 'SCHEDULE_FLY_TO_XY' || command.type === 'SCHEDULE_TAKEOFF') {
                const { x, y } = command.arguments;

                if (x < minX) minX = x;
                if (x > maxX) maxX = x;
                if (y < minY) minY = y;
                if (y > maxY) maxY = y;
            }
        }

        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
        };
    }


    const focus = useCallback(({ x, y, width, height, margin }) => {
        if (viewportRef.current && !disableAutoFocus) {
            console.log(`Focussing on x: ${x}, y: ${y}, width: ${width}, height: ${height}, margin: ${margin}`);
            x = x + worldMargin;
            y = y - worldMargin; // For y we need to subtract because direction of y in world is flipped with respect to map
            return viewportRef.current.focus(
                worldToMap({
                    x,
                    y,
                    width: width ?? null,
                    height: height ?? null,
                    margin: margin ?? null,
                    minVisibleLength: 10,
                    mapHeight: (worldDimensions?.height ?? 0) * worldMapScaleFactor
                })
            );
        }
    }, [viewportRef, worldDimensions?.height, disableAutoFocus]);

    useEffect(() => {
        if (viewportRef.current && taskPreviewCommands && taskPreviewCommands.length > 0 && worldDimensions?.height) {
            focus({ ...getCommandsArea(taskPreviewCommands), margin: 5 })
        }
    }, [taskPreviewCommands, worldDimensions?.height, focus])

    useEffect(() => {
        if (viewportRef.current && resumePreviewCommands && resumePreviewCommands.length > 0 && worldDimensions?.height) {
            focus({ ...getCommandsArea(resumePreviewCommands), margin: 5 })
        }
    }, [resumePreviewCommands, worldDimensions?.height, focus])

    useEffect(() => {
        if (viewportRef.current && flight?.commands && flight?.commands.length > 0 && worldDimensions?.height) {
            focus({ ...getCommandsArea(flight?.commands), margin: 5 })
            // Don't refocus on flight commands update
            setDisableAutoFocus(true);
        }
    }, [flight?.commands, worldDimensions?.height, focus])

    useEffect(() => {
        if (viewportRef.current && measurements && measurements.length > 0 && worldDimensions?.height) {
            if (focus({ ...getMeasurementsArea(measurements), margin: 5 })) {
                // Disable auto focus such that we don't refocus when measurements are updated during uploading
                console.log("Disable auto focus");
                setDisableAutoFocus(true);
            }
        }
    }, [measurements, worldDimensions?.height, focus])

    useEffect(() => {
        if (viewportRef.current && point && worldDimensions?.height) {
            if (focus({ ...point, margin: 10 })) {
                console.log("Disable auto focus");
                // Disable auto focus such that other components don't steal focus. 
                // Kind of relying on that point is known earlier than the rest. TODO: Improve
                setDisableAutoFocus(true);
            }
        }
    }, [point, worldDimensions?.height, focus])

    useEffect(() => {
        if (hoverImageInfo?.imageUrl) {
            setHoverImageError(false);
        }
    }, [hoverImageInfo?.imageUrl]);

    const getDroneById = (droneId) => drones.find(drone => drone?.droneId === droneId);
    const isDroneSleeping = (droneId) => getDroneById(droneId)?.isSleeping ?? false;

    return (
        <>
            {previewAlert &&
                <Alert variant={previewAlert.variant} className="m-0 d-flex justify-content-between ">
                    <div className="d-flex align-items-center me-3">
                        <FaExclamationCircle
                            className={`mt-1 me-2 text-danger flex-shrink-0`}
                            style={{ marginTop: "-2px" }}
                        />
                        <div>
                            {previewAlert.message}
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-shrink-0">
                        <Button
                            className="ms-2 mb-1 px-2 py-1"
                            onClick={() => {
                                // Load both previews, the one which has all its settings will actually be loaded
                                loadTaskPreview();
                                loadResumePreview();
                            }}
                        >
                            <small>
                                <FaRedo className="me-2" style={{ marginTop: "-2px" }} />
                                Retry
                            </small>
                        </Button>
                        {previewAlert.droneId && isDroneSleeping(previewAlert.droneId) && (
                            <Button
                                className="ms-2 px-2 py-1"
                                disabled={hasSentWakeUp}
                                onClick={() => {
                                    setHasSentWakeUp(true);
                                    api.sendWakeUp(previewAlert.droneId);
                                    setTimeout(() => {
                                        setHasSentWakeUp(false);
                                    }, 5000);
                                }}
                            >
                                <small>
                                    {hasSentWakeUp ? (
                                        <>
                                            <FaClock className="me-2" style={{ marginTop: "-2px" }} />
                                            Waking up {previewAlert.droneId}
                                        </>
                                    ) : (
                                        <>
                                            <FaClock className="me-2" style={{ marginTop: "-2px" }} />
                                            Wake up {previewAlert.droneId}
                                        </>
                                    )}
                                </small>
                            </Button>
                        )}

                    </div>
                </Alert>
            }
            <div
                ref={mapRef}
                style={{
                    position: 'relative',
                    width: '100%', // Use up the full space. Define the available space outside the map
                    height: '100%',
                    borderRadius: 'inherit', // Inherit borderRadius to look nice in Cards
                    overflow: 'hidden' // Hide overflow to look nice in Cards
                }}>
                <div>
                    {worldDimensions && frameDimensions &&
                        <Stage
                            width={frameDimensions.width}
                            height={frameDimensions.height}
                            options={{ backgroundColor: 0xeeeeee, antialias: true }}
                        >
                            <MapProvider value={mapContextValue}>
                                <Viewport
                                    ref={viewportRef}
                                    screenWidth={frameDimensions.width}
                                    screenHeight={frameDimensions.height}
                                    worldHeight={(worldDimensions.height + 2 * worldMargin) * worldMapScaleFactor}
                                    worldWidth={(worldDimensions.width + 2 * worldMargin) * worldMapScaleFactor}
                                >
                                    <Container position={[worldMargin * worldMapScaleFactor, worldMargin * worldMapScaleFactor]} >
                                        {/* <XAxis y={0} />
                                    <YAxis x={0} />
                                    <ScrollBorder
                                        x={-worldMargin}
                                        y={-worldMargin}
                                        width={worldDimensions.width + 2 * worldMargin}
                                        height={worldDimensions.height + 2 * worldMargin}
                                    /> */}
                                        <WorldBorder
                                            x={0}
                                            y={0}
                                            width={worldDimensions.width}
                                            height={worldDimensions.height}
                                        />
                                        <LocationTitle name={location?.name || ""} x={0} y={worldDimensions.height + 12.5} />
                                        {
                                            Object.keys(areas).map((areaKap) =>
                                                Object.keys(areas[areaKap]).map((areaPole) => (
                                                    <Area
                                                        key={`${areaKap}_${areaPole}`}
                                                        label={`${areaKap}\n${areaPole}`}
                                                        x={areas[areaKap][areaPole].min.x}
                                                        y={areas[areaKap][areaPole].min.y}
                                                        width={(areas[areaKap][areaPole].max.x - areas[areaKap][areaPole].min.x)}
                                                        height={(areas[areaKap][areaPole].max.y - areas[areaKap][areaPole].min.y)}
                                                    />
                                                ))
                                            )
                                        }
                                        {flyzones.map((flyzone) => (
                                            <Flyzone
                                                key={`${flyzone.name}_${flyzone.x_min}_${flyzone.x_max}_${flyzone.y_min}_${flyzone.y_max}`}
                                                label={flyzone.name}
                                                x={flyzone.x_min}
                                                y={flyzone.y_min}
                                                width={(flyzone.x_max - flyzone.x_min)}
                                                height={(flyzone.y_max - flyzone.y_min)}
                                                flyable={flyzone.flyable ?? true}
                                            />
                                        ))}
                                        {
                                            markers?.markers?.map((marker, index) => (
                                                <Marker
                                                    key={index}
                                                    label={marker.id}
                                                    x={Number(marker.x)}
                                                    y={Number(marker.y)}
                                                    width={Number(marker.width) / 100}
                                                    rotation={Number(marker.yaw)}
                                                    valid={marker.valid} />
                                            ))
                                        }
                                        {measurements.map((measurement) => (
                                            <Measurement
                                                key={measurement.id}
                                                x={measurement.meta.position.x}
                                                y={measurement.meta.position.y}
                                                yaw={measurement?.meta?.rotation?.y}
                                                url={urls.measurement(organizationId, locationId, missionId, measurement.id)}
                                                imageLocation={`organizations/${organizationId}/locations/${locationId}/measurements/512x512/${measurement.id}_512x512.jpg`}
                                                onClick={() => window.open(urls.measurement(organizationId, locationId, missionId, measurement.id), '_blank')}
                                                onHover={setHoverImageInfo}
                                            />
                                        ))}
                                        {docks.map((dock) => (
                                            <Dock
                                                key={dock.id}
                                                x={dock.position.x}
                                                y={dock.position.y}
                                                yaw={dock.yaw}
                                            />
                                        ))}
                                        {taskPreviewCommands && taskPreviewCommands.length > 0 &&
                                            <DroneCommands
                                                droneCommands={taskPreviewCommands}
                                                onHover={setHoverTextInfo}
                                            />
                                        }
                                        {resumePreviewCommands && resumePreviewCommands.length > 0 &&
                                            <DroneCommands
                                                droneCommands={resumePreviewCommands}
                                                onHover={setHoverTextInfo}
                                            />
                                        }
                                        {
                                            flightCommands.length > 0 && (
                                                <>
                                                    <DroneCommands
                                                        droneCommands={flightCommands}
                                                        abortEvents={fligthAbortEvents}
                                                        onHover={setHoverTextInfo}
                                                    />
                                                    <ReturnPath
                                                        drone={drones.find(drone => drone?.droneId === flight?.drone?.id)}
                                                        droneCommands={flightCommands}
                                                        onHover={setHoverTextInfo}
                                                    />
                                                </>
                                            )
                                        }
                                        {drones.filter(drone => !drone.isOnline) // First draw offline drones
                                            .map(({ droneId, x, y, yaw }) => (
                                                <Drone key={droneId} droneId={droneId} x={x} y={y} yaw={yaw} isOnline={false} />
                                            ))}

                                        {drones.filter(drone => drone.isOnline) // Then draw online drones
                                            .map(({ droneId, x, y, yaw }) => (
                                                <Drone key={droneId} droneId={droneId} x={x} y={y} yaw={yaw} isOnline={true} />
                                            ))}
                                        {point && point.x !== undefined && point.y !== undefined &&
                                            <Point
                                                x={point.x}
                                                y={point.y}
                                                color={0x00ffff}
                                                strokeColor={0x000000}
                                            />}
                                    </Container>
                                </Viewport>
                            </MapProvider>
                        </Stage>
                    }
                </div>
                {hoverTextInfo && (
                    <div
                        id="hover-tooltip"
                        style={{
                            position: 'absolute',
                            top: hoverTextInfo.y,
                            left: hoverTextInfo.x,
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            color: 'white',
                            padding: '5px',
                            borderRadius: '5px',
                            pointerEvents: 'none',
                            transform: 'translate(-50%, calc(-100% - 15px))', // Center horizontally and move above the cursor
                            whiteSpace: 'nowrap', // Prevent text wrapping
                        }}
                    >
                        {hoverTextInfo.text}
                    </div>
                )}
                {hoverImageInfo && (
                    <div
                        id="hover-image"
                        style={{
                            position: 'absolute',
                            top: hoverImageInfo.y,
                            left: hoverImageInfo.x,
                            pointerEvents: 'none',
                            transform: `translate(-50%, ${hoverImageInfo.y > (frameDimensions?.height ?? 0) / 2 ? 'calc(-100% - 30px)' : '30px'})`,
                        }}
                    >
                        {hoverImageInfo.imageUrl &&
                            (hoverImageError ?
                                <div
                                    style={{
                                        width: 'auto',
                                        height: 'auto',
                                        padding: '5px',
                                        backgroundColor: '#f0f0f0',
                                        color: '#888',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '5px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
                                    }}
                                >
                                    Image not available
                                </div>
                                :
                                <img
                                    src={hoverImageInfo.imageUrl}
                                    alt="Overlay"
                                    style={{
                                        height: '40vh',
                                        width: 'auto',
                                        maxWidth: '512px',
                                        borderRadius: '5px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
                                        transform: `rotate(${hoverImageInfo?.rotation ?? 0}rad)`, // Apply the rotation in radians
                                    }}
                                    onError={() => setHoverImageError(true)}
                                />
                            )
                        }
                    </div>
                )}
                {isLoading &&
                    <div style={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        zIndex: 100, // Ensure it's above the map
                        pointerEvents: 'none', // Ensure it does not interfere with interactions on the map
                    }}
                    >
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
            </div>
        </>
    );
}