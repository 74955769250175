import React from "react";
import useMapPrecomputeProgress from "../../hooks/useMapPrecomputeProgress";
import { Alert, Spinner } from "react-bootstrap";

export default function MapOptimizationAlert({ organizationId, locationId, location, className }) {
    const precomputeProgress = useMapPrecomputeProgress({ organizationId, locationId, location });
    const isPrecomputing = precomputeProgress < 1.0;

    return (
        isPrecomputing ? (
            <Alert
                variant="warning"
                className={className}
            >
                <Spinner animation="border" variant="warning" size="sm" className="me-2" /> Optimizing map. This will take a few seconds.
            </Alert>
        ) : null
    );
}