import React from "react";
import { Button, ButtonGroup, Card, Dropdown, ListGroup, Spinner, Col, Row } from "react-bootstrap";
import { FaCode, FaEdit, FaDatabase, FaTrash } from "react-icons/fa";
import { MdPower, MdPowerOff } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import CodeBlock from "../../components/CodeBlock";
import Layout, { DockBreadcrumbContainer, Title2 } from "../../components/Layout/Layout";
import DevMenuButton from "../../components/DevMenuButton";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";

export default function DockPage(props) {
    const [dock, setDock] = React.useState();
    const [dockDroneIds, setDockDroneIds] = React.useState([]);
    const history = useHistory();
    const { getUserIsAdmin } = useUser();
    const { organizationId, locationId, dockId } = useParams();
    const [view, setView] = React.useState('default');
    const [isLoading, setIsLoading] = React.useState(false);
    const calibrationPoint =
        dock?.position?.x != null &&
            dock?.position?.y != null &&
            dock?.position?.z != null &&
            dock?.calibrationPointOffset?.x != null &&
            dock?.calibrationPointOffset?.y != null &&
            dock?.calibrationPointOffset?.z != null &&
            dock?.takeoffHeight != null
            ? {
                x: Number((Number(dock.position.x) + Number(dock.calibrationPointOffset.x)).toFixed(2)),
                y: Number((Number(dock.position.y) + Number(dock.calibrationPointOffset.y)).toFixed(2)),
                z: Number((Number(dock.position.z) + Number(dock.takeoffHeight) + Number(dock.calibrationPointOffset.z)).toFixed(2)),
            }
            : undefined;

    React.useEffect(() => {
        setIsLoading(true)
        api.getDock(organizationId, locationId, dockId, (dock) => {
            setDock(dock);
            setIsLoading(false);
        })
    }, [organizationId, locationId, dockId]);

    React.useEffect(() => {
        return api.getDockDroneIds(organizationId, locationId, dockId, setDockDroneIds);
    }, [organizationId, locationId, dockId]);

    function onDeleteDock() {
        api.deleteDock(organizationId, locationId, dockId)
            .then(() => {
                api.getDockDroneIds(organizationId, locationId, dockId, (dockDroneIds => {
                    dockDroneIds.forEach((droneId) => {
                        api.getDroneConfig(droneId, (droneConfig) => {
                            console.log(`Removing dock ${dockId} from drone ${droneId}`)
                            droneConfig.docks = droneConfig.docks.filter((dock) => { return dock.id !== dockId; });
                            api.updateDroneConfig(droneId, droneConfig);
                        })
                    })
                }))

                history.push(urls.docks(organizationId, locationId));
            });
    }

    function visitFirestore() {
        const organizationPath = `organizations/${organizationId}`;
        const locationPath = `${organizationPath}/locations/${locationId}`;
        const dockPath = `${locationPath}/docks/${dockId}`;

        window.open(`https://console.firebase.google.com/u/0/project/corvus-backend/firestore/data/~2F${dockPath}`);
    }

    return (
        <Layout>
            <DockBreadcrumbContainer />
            <Card>
                <Title2
                    backButtonUrl={urls.docks(organizationId, locationId)}
                    title={
                        <Row className="d-flex align-items-center p-0">
                            <Col style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                Dock {dock?.name ?? ""}
                            </Col>
                        </Row>
                    }
                >
                    {getUserIsAdmin() && (
                        <DevMenuButton className="ms-1 mb-1">
                            <Dropdown.Item
                                onClick={() => setView(view === 'default' ? 'data' : 'default')}
                            >
                                <FaCode
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                {view === 'default' ? 'View Data' : 'View Default'}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={visitFirestore}
                            >
                                <FaDatabase
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                View Firestore
                            </Dropdown.Item>
                        </DevMenuButton>
                    )}
                    <Dropdown as={ButtonGroup} className="ms-1 mb-1">
                        <Button
                            variant="outline-secondary"
                            as={Link}
                            to={urls.dockSettings(organizationId, locationId, dockId)}
                        >
                            <FaEdit
                                className="me-2"
                                style={{
                                    marginTop: "-2px",
                                }}
                            />
                            Edit
                        </Button>
                        <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />
                        <Dropdown.Menu align="end">
                            <Dropdown.Item
                                onClick={onDeleteDock}
                                variant="outline-danger"
                                className="text-danger"
                            >
                                <FaTrash
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                Delete
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Title2>
                {isLoading ? (
                    <Spinner animation="border" role="status" variant="primary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) :
                    <>
                        {view === 'data' && (
                            <Card.Body className='p-4 pt-0'>
                                <CodeBlock name='Dock' code={dock} />
                            </Card.Body>
                        )}
                        {view === 'default' && (
                            <ListGroup variant="flush">
                                <ListGroup.Item className="px-4">
                                    <b>Powered (only rpv3)</b>
                                    <span className="ms-2">
                                        {dock?.powered === undefined ? "Not defined" :
                                            (dock.powered ? <>Yes<MdPower className="text-success ms-1" /></> : <>No<MdPowerOff className="text-secondary ms-1" /></>)}
                                    </span>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-4">
                                    <b>Position</b>
                                    <span className="ms-2">
                                        {(dock?.position?.x !== undefined &&
                                            dock?.position?.y !== undefined &&
                                            dock?.position?.z !== undefined) ?
                                            `x: ${dock.position.x}, y: ${dock.position.y}, z: ${dock.position.z}` : "Not defined"
                                        }
                                    </span>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-4">
                                    <b>Yaw</b>
                                    <span className="ms-2">
                                        {dock?.yaw === undefined ? "Not defined" : `${dock.yaw}°`}
                                    </span>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-4">
                                    <b>Takeoff height (only rpv3)</b>
                                    <span className="ms-2">
                                        {dock?.takeoffHeight === undefined ? "Not defined" : `${dock.takeoffHeight} m`}
                                    </span>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-4">
                                    <b>Calibration point offset (only rpv3)</b>
                                    <span className="ms-2">
                                        {(dock?.calibrationPointOffset?.x !== undefined &&
                                            dock?.calibrationPointOffset?.y !== undefined &&
                                            dock?.calibrationPointOffset?.z !== undefined) ?
                                            `x: ${dock.calibrationPointOffset.x}, y: ${dock.calibrationPointOffset.y}, z: ${dock.calibrationPointOffset.z}` : "Not defined"
                                        }
                                    </span>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-4">
                                    <b>Calibration point (only rpv3)</b>
                                    <span className="ms-2">
                                        {(calibrationPoint !== undefined) ?
                                            `x: ${calibrationPoint.x}, y: ${calibrationPoint.y}, z: ${calibrationPoint.z}` : "Not defined"
                                        }
                                    </span>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-4">
                                    <b>Drone</b>
                                    <span className="ms-2">
                                        {dockDroneIds.length > 0
                                            ? dockDroneIds.map((dockDrone, index) => (
                                                <React.Fragment key={dockDrone}>
                                                    <Link to={urls.locationDrone(organizationId, locationId, dockDrone)}>
                                                        {dockDrone}
                                                    </Link>
                                                    {index < dockDroneIds.length - 1 && ", "}
                                                </React.Fragment>
                                            ))
                                            : "No Drone"}
                                    </span>
                                </ListGroup.Item>
                            </ListGroup>
                        )}
                    </>}
            </Card>
        </Layout>
    );
}
